import { getProjects, getProjectById } from "../api/project";
import { useMemo } from "react";

export const useProject = () => {
  return useMemo(() => {
    const handleFetchProjects = async ({ setLoading = () => {} }) => {
      setLoading(true);

      try {
        const { data } = await getProjects();

        if (!data) return [];

        return data || [];
      } catch (error) {
        console.log("could not fetch projects ", error);
        return { error };
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 300);
      }
    };

    const handleFetchProjectById = async ({ id, setLoading = () => {} }) => {
      setLoading(true);
      try {
        const { data } = await getProjectById(id);

        if (!data) return {};
        return data;
      } catch (error) {
        console.log("could not fetch project with id: ", id, error);
        return { error };
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 300);
      }
    };

    return {
      handleFetchProjects,
      handleFetchProjectById,
    };
  }, []);
};
