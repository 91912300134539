import React from "react";
import { Link } from "react-router-dom";
import { RUTAS } from "../../constants/routes.constants";
import { useLang } from "../../translations/i18n";

const BeforeProjectButton = ({ beforeProject }) => {
  const lang = useLang();
  return (
    <Link
      to={RUTAS.PROJECT[lang].replace(":id", beforeProject?.title)}
      className="f30 d-flex align-items-center"
    >
      <span className="d-flex">
        <svg
          width="25"
          height="30"
          viewBox="0 0 9 16"
          xmlns="http://www.w3.org/2000/svg"
          transform="rotate(180)"
        >
          <polyline
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
            strokeMiterlimit="10"
            points="1,1 8,8 1,15"
          ></polyline>
        </svg>
      </span>
    </Link>
  );
};

export default BeforeProjectButton;
