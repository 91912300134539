import React, { useRef, useEffect, useState } from "react";
import { SERVER_URL } from "../../utils";

const GalleryItem = ({ resource, index }) => {
  const imgRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    if (imgRef.current) observer.observe(imgRef.current);

    return () => {
      if (imgRef.current) observer.disconnect();
    };
  }, []);

  const fileType = (file) => {
    const imageExtensions = [".png", ".jpg", ".jpeg", ".gif"];
    const videoExtensions = [".mp4", ".webm", ".mov"];
    const extension = file.substring(file.lastIndexOf(".")).toLowerCase();
    return imageExtensions.includes(extension)
      ? "image"
      : videoExtensions.includes(extension)
      ? "video"
      : "unknown";
  };

  return (
    <div ref={imgRef} className={`gallery-item ${isVisible ? "fade-in" : ""}`}>
      {fileType(resource) === "image" ? (
        <img
          src={`${SERVER_URL}/${resource}`}
          alt={`Resource ${resource.lastIndexOf(".")}`}
          style={{ width: "100%", height: "auto" }}
          loading="lazy"
        />
      ) : (
        <video
          src={`${SERVER_URL}/${resource}`}
          alt={`Resource ${resource.lastIndexOf(".")}`}
          controls
          style={{ width: "100%", height: "auto" }}
          loop
        />
      )}
    </div>
  );
};

export default GalleryItem;
