import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import { useProject } from "../hooks/useProjects";
import { useProjectsContext } from "../contexts/ProjectsContext";
import { Link } from "react-router-dom";
import { RUTAS } from "../constants/routes.constants";
import ProjectCard from "../components/project/ProjectCard";

function Home({ intl }) {
  const lang = useLang();
  // const [projects, setProjects] = useState([]);
  const { projects, setProjects } = useProjectsContext();
  const { handleFetchProjects } = useProject();

  useEffect(() => {
    handleFetchProjects({ setLoading: () => {} }).then((data) => {
      setProjects(data.sort((a, b) => a.order - b.order));
    });
  }, [handleFetchProjects]);

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "HOME.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "HOME.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container my-5">
        <div className="row fadeIn">
          {projects?.length > 0 ? (
            projects?.map((project) =>
              project.blank ? (
                <span className="d-none d-lg-block col-lg-3 col-md-6 col-12 px-0">
                  <ProjectCard project={project} />
                </span>
              ) : (
                <Link
                  to={RUTAS.PROJECT[lang].replace(":id", project?.title)}
                  className="col-lg-3 col-md-4 col-4 px-1 px-lg-0"
                  key={project?._id}
                >
                  <ProjectCard project={project} />
                </Link>
              )
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default injectIntl(Home);
