/* eslint-disable jsx-a11y/anchor-is-valid */
import "./App.css";
import { FormattedMessage, injectIntl } from "react-intl";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { setLanguage, useLang } from "./translations/i18n";
import Top from "./components/navigation/Top";
import Footer from "./components/Footer";
import Cookie from "./components/Cookie";
import { ProjectsProvider } from "./contexts/ProjectsContext";
import { BackgroundColorProvider } from "./contexts/BackgroundColorContext";

function App({ intl }) {
  const { pathname } = useLocation();
  const lang = useLang();
  const [menuOpen, setMenuOpen] = useState(false);

  function handleClick(e, option) {
    e.stopPropagation();
    // console.log(option);
  }

  useEffect(() => {
    // eslint-disable-next-line
    const [_, url_lang] = pathname.split("/");
    if (url_lang === "ca" && lang !== "ca") setLanguage("ca");
    else if (url_lang === "en" && lang !== "en") setLanguage("en");
    else if (url_lang !== "ca" && url_lang !== "en" && lang !== "es")
      setLanguage("es");
  }, [lang, pathname]);

  return (
    <BackgroundColorProvider>
      <ProjectsProvider>
        <div className="App">
          <div className="content">
            <header style={{ position: "relative" }}>
              <Top
                handleClick={handleClick}
                menuOpen={menuOpen}
                pathname={pathname}
              />
            </header>

            <Outlet />

            <Cookie />
          </div>
          <Footer />
        </div>
      </ProjectsProvider>
    </BackgroundColorProvider>
  );
}

export default injectIntl(App);
