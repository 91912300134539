import { useBackgroundColor } from "../contexts/BackgroundColorContext";
import TranslatedLink from "./navigation/TranslatedLink";
import { useLang } from "../translations/i18n";
import { RUTAS } from "../constants/routes.constants";

export default function Footer() {
  const { backgroundColor } = useBackgroundColor();
  const lang = useLang();
  return (
    <footer className="footer" style={{ backgroundColor }}>
      <div className="container">
        <div className="row justify-content-start text-start">
          <div className="col py-3 px-3">
            <TranslatedLink to={RUTAS.HOME}>
              <span className="fw-bold">Home</span>
            </TranslatedLink>
          </div>
          <div className="col py-3 px-3">
            <span>Si deseas conocernos ponte en contacto con nosotros:</span>
          </div>
          <div className="col py-3 px-3">
            <TranslatedLink to={RUTAS.CONTACT}>
              <span className="fw-bold">Contacto</span>
            </TranslatedLink>
            <p>(+34) 635 906 042</p>
          </div>
          <div className="col py-3 px-3">
            <TranslatedLink to={RUTAS.PRIVACIDAD}>
              Política de privacidad
            </TranslatedLink>
            <TranslatedLink to={RUTAS.COOKIES}>
              Política de Cookies
            </TranslatedLink>
          </div>
        </div>
      </div>
    </footer>
  );
}
