export const RUTAS = {
  HOME: {
    es: "/",
    en: "/en",
    ca: "/ca",
  },
  INFO: {
    es: "/informacion",
    en: "/en/information",
    ca: "/ca/informació",
  },
  CONTACT: {
    es: "/contacto",
    en: "/en/contact",
    ca: "/ca/contacte",
  },
  PROJECT: {
    es: "/proyectos/:id",
    en: "/en/projects/:id",
    ca: "/ca/projectes/:id",
  },
  PRIVACIDAD: {
    es: "/politica-de-privacidad",
    en: "/en/privacy-policy",
    ca: "/ca/politica-de-privacitat",
  },
  AVISO_LEGAL: {
    es: "/aviso-legal",
    en: "/en/legal-notice",
    ca: "/ca/avís-legal",
  },
  COOKIES: {
    es: "/politica-de-cookies",
    en: "/en/cookie-policy",
    ca: "/ca/politica-de-cookies",
  },
};
