import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import Home from "./pages/Home";
import Info from "./pages/Info";
import Contact from "./pages/Contact";
import Project from "./pages/Project";
import PoliticaCookies from "./pages/PoliticaCookies";
import PoliticaPrivacidad from "./pages/PoliticaPrivacidad";
import AvisoLegal from "./pages/AvisoLegal";
import { RUTAS } from "./constants/routes.constants";
import NotFound from "./pages/Page404";

function Rutas() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path={RUTAS.HOME.es} element={<Home />} />
          <Route path={RUTAS.HOME.ca} element={<Home />} />
          <Route path={RUTAS.HOME.en} element={<Home />} />

          <Route path={RUTAS.INFO.es} element={<Info />} />
          <Route path={RUTAS.INFO.ca} element={<Info />} />
          <Route path={RUTAS.INFO.en} element={<Info />} />

          <Route path={RUTAS.CONTACT.es} element={<Contact />} />
          <Route path={RUTAS.CONTACT.ca} element={<Contact />} />
          <Route path={RUTAS.CONTACT.en} element={<Contact />} />

          <Route path={RUTAS.PROJECT.es} element={<Project />} />
          <Route path={RUTAS.PROJECT.ca} element={<Project />} />
          <Route path={RUTAS.PROJECT.en} element={<Project />} />

          <Route path={RUTAS.PRIVACIDAD.es} element={<PoliticaPrivacidad />} />
          <Route path={RUTAS.PRIVACIDAD.ca} element={<PoliticaPrivacidad />} />
          <Route path={RUTAS.PRIVACIDAD.en} element={<PoliticaPrivacidad />} />

          <Route path={RUTAS.AVISO_LEGAL.es} element={<AvisoLegal />} />
          <Route path={RUTAS.AVISO_LEGAL.ca} element={<AvisoLegal />} />
          <Route path={RUTAS.AVISO_LEGAL.en} element={<AvisoLegal />} />

          <Route path={RUTAS.COOKIES.es} element={<PoliticaCookies />} />
          <Route path={RUTAS.COOKIES.ca} element={<PoliticaCookies />} />
          <Route path={RUTAS.COOKIES.en} element={<PoliticaCookies />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Rutas;
