import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import { getTexts } from "../api/text";

function PoliticaCookies({ intl }) {
  const [text, setText] = useState("");
  const lang = useLang();

  useEffect(() => {
    window.scrollTo(0, 0);
    getTexts()
      .then((res) => {
        if (res.status === 200) {
          const data = res.data.filter(
            (t) => t.title[lang] === "POLITICA_DE_COOKIES"
          );
          setText(data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "POLITICACOOKIES.TITLESEO" }) || ""}
        description={
          intl.formatMessage({ id: "POLITICACOOKIES.DESCRIPTIONSEO" }) || ""
        }
      />
      <div className="container pb-5">
        <div className="row">
          <h3
            className="col-12 text-justify"
            dangerouslySetInnerHTML={{ __html: text?.title?.[lang] }}
          />
          <div
            className="col-12 text-justify"
            dangerouslySetInnerHTML={{ __html: text?.content?.[lang] }}
          />
        </div>
      </div>
    </>
  );
}

export default injectIntl(PoliticaCookies);
