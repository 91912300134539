import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useParams } from "react-router-dom";
import { useProject } from "../hooks/useProjects";
import GalleryItem from "../components/project/GalleryItem";
import NextProjectButton from "../components/project/NextProjectButton";
import BeforeProjectButton from "../components/project/BeforeProjectButton";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useBackgroundColor } from "../contexts/BackgroundColorContext";

function Project() {
  const { id } = useParams();
  const { handleFetchProjectById, handleFetchProjects } = useProject();
  const [project, setProject] = useState({});
  const [loading, setLoading] = useState(true);
  const [nextProject, setNextProject] = useState({});
  const [beforeProject, setBeforeProject] = useState({});
  const { setBackgroundColor } = useBackgroundColor(); // Use the context

  useEffect(() => {
    handleFetchProjects({ setLoading: setLoading }).then((data) => {
      const filteredData = data.filter((p) => !p.blank);
      const currentProjectIndex = filteredData.findIndex((p) => p.title === id);
      setNextProject(filteredData[currentProjectIndex + 1] || filteredData[0]);
      setBeforeProject(
        filteredData[currentProjectIndex - 1] ||
          filteredData[filteredData.length - 1]
      );
      const currentProject = filteredData.find((p) => p.title === id) || {};
      setProject(currentProject);
      setBackgroundColor(currentProject.background || ""); // Set the background color
      setLoading(false);
    });
    window.scrollTo(0, 0);
  }, [id, handleFetchProjects, setBackgroundColor]);

  const loader = (
    <div className="container my-5">
      <div className="row">
        <div className="col-12">
          <Skeleton height={50} width={300} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Skeleton count={3} height={500} />
        </div>
      </div>
    </div>
  );

  return (
    <div key={id} style={{ backgroundColor: project.background }}>
      {loading ? loader : null}
      <CustomHelmet title={project.title} description={project.description} />
      <div
        className="container p-5 fadeIn"
        style={{ textAlign: "justify", textJustify: "inter-word" }}
      >
        <div dangerouslySetInnerHTML={{ __html: project.description }} />
        <section className="my-5">
          {project?.gallery?.map((resource, index) => (
            <GalleryItem key={index} resource={resource} index={index} />
          ))}
        </section>
        <span className="d-flex direction-row justify-content-between">
          <BeforeProjectButton beforeProject={beforeProject} />
          <NextProjectButton nextProject={nextProject} />
        </span>
      </div>
    </div>
  );
}

export default Project;
