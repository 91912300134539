import React, { useEffect, useState } from "react";
import {
  CookieConsentBanner,
  triggerCookieConsentBanner,
} from "@porscheofficial/cookie-consent-banner-react";

const initConsent = ({ acceptedCategories }) => {
  // -------------------------------------------------------------------------
  // Error Tracking Service
  // Analytics
  // -------------------------------------------------------------------------
  if (acceptedCategories.includes("analytics")) {
    // ErrorTrackingService.init({
    //   dsn: process.env.DSN,
    //   environment: process.env.ENV,
    // });
  }
};

const Cookie = ({ children }) => {
  const [acceptedCategories, setAcceptedCategories] = useState([]);

  return (
    <div>
      <header>
        {(Boolean(acceptedCategories.includes("analytics")) ||
          Boolean(acceptedCategories.includes("marketing"))) &&
          process.env.ENV === "prod" && (
            <script id="tagmanager-script" key="tagmanager-script">
              {/* Load Tag Manager Script */}
            </script>
          )}
      </header>
      <div>{children}</div>
      <CookieConsentBanner
        handlePreferencesUpdated={initConsent}
        handlePreferencesRestored={initConsent}
        btnLabelAcceptAndContinue="Aceptar y continuar"
        btnLabelSelectAllAndContinue="Seleccionar todas y continuar"
        btnLabelOnlyEssentialAndContinue="Continuar solo con cookies técnicamente necesarias"
        btnLabelPersistSelectionAndContinue="Guardar selección y continuar"
        contentSettingsDescription="Puede decidir qué cookies se utilizan seleccionando las opciones respectivas a continuación. Tenga en cuenta que su selección puede afectar la funcionalidad del servicio."
        availableCategories={[
          {
            description:
              "Le permite navegar y utilizar las funciones básicas y almacenar preferencias.",
            key: "technically_required",
            label: "Cookies técnicamente necesarias",
            isMandatory: true,
          },
          {
            description:
              "Nos permite determinar cómo los visitantes interactúan con nuestro servicio para mejorar la experiencia del usuario.",
            key: "analytics",
            label: "Cookies de análisis",
          },
          {
            description:
              "Nos permite ofrecer y evaluar contenido relevante y publicidad basada en intereses.",
            key: "marketing",
            label: "Cookies de marketing",
          },
        ]}
      >
        Utilizamos cookies y tecnologías similares para proporcionar ciertas
        funciones, mejorar la experiencia del usuario y entregar contenido
        relevante para sus intereses. Dependiendo de su propósito, se pueden
        utilizar cookies de análisis y marketing además de las cookies
        técnicamente necesarias. Al hacer clic en "Aceptar y continuar", usted
        declara su consentimiento al uso de las cookies mencionadas
        anteriormente. Puede realizar ajustes detallados o revocar su
        consentimiento (en parte si es necesario) con efecto para el futuro. Haz
        click {"  "}
        <button
          onClick={() => {
            triggerCookieConsentBanner({ showDetails: true });
          }}
          onKeyDown={() => {
            triggerCookieConsentBanner({ showDetails: true });
          }}
          type="button"
        >
          Aquí
        </button>
        {"  "}
        Para obtener más información, consulte nuestra
        <a href="/politica-de-privacidad"> Política de Privacidad</a>.
        <br />
      </CookieConsentBanner>
    </div>
  );
};

export default Cookie;
