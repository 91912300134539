import axios from "axios";
import { API } from "../../utils/index";
const instance = axios.create();

export const getProjects = async () => {
  return instance.get(`${API}/project`);
};

export const getProjectById = async (id) => {
  return instance.get(`${API}/project/${id}`);
};
