import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";
import { useLang } from "../translations/i18n";

function Info({ intl }) {
  const lang = useLang();
  const { ref, inView /*, entry*/ } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 1500,
    triggerOnce: true,
  });

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "OURCLIENTS.TITLESEO" }) || ""}
        description={
          intl.formatMessage({ id: "OURCLIENTS.DESCRIPTIONSEO" }) || ""
        }
      />
      <div className="container my-5 fadeIn">
        <div className="row">
          <h1 className="text-justify">
            We help brands differentiate themselves through creative thinking.
            We create strategies, concepts and visual codes that connect
            emotionally with people.
          </h1>
          <div className="col-12 text-center py-5">
            <div>
              <h1>
                Algunos de los clientes
                <br />
                con los que hemos trabajado
              </h1>
            </div>
          </div>
          <div className="col-12">
            <div className="container-fluid">
              <div className="row text-center">
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
                <div className="col-2">LOGO</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Info);
