import { getLogos } from "../api/logo";
import { useMemo } from "react";

export const useLogo = () => {
  return useMemo(() => {
    const handleFetchLogos = async ({ setLoading = () => {} }) => {
      setLoading(true);

      try {
        const { data } = await getLogos();

        /* gestionar quan la resposta es buida (si es dona el cas) */
        if (!data) return [];

        return data || [];
      } catch (error) {
        /* gestionar el error */
        console.log("could not fetch logos ", error);
        return { error };
      } finally {
        setLoading(false);
      }
    };

    // const handleFetchLogoById = async ({ id, setLoading = () => {} }) => {
    //   setLoading(true);

    //   try {
    //     const { data } = await getLogoById(id);

    //     /* gestionar quan la resposta es buida (si es dona el cas) */
    //     if (!data) return {};
    //     return { data };
    //   } catch (error) {
    //     /* TODO -> Handle error */
    //     /* gestionar el error */
    //     console.log("could not fetch logos with id: ", id, error);
    //     return { error };
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    return {
      handleFetchLogos,
      // handleFetchLogoById,
    };
  }, []);
};
