import React from "react";
import "./ProjectCard.css";
import { SERVER_URL } from "../../utils";

const ProjectCard = ({ project }) => {
  const { title, mainImgUrl } = project;

  return (
    <div
      className={`project-card ${project.blank ? "" : "project-card-active"} `}
      style={{ cursor: !project.blank ? "pointer" : "default" }}
    >
      <div
        className={`project-image ${
          project.blank ? "" : "project-image-active"
        }`}
        style={{ backgroundImage: `url(${SERVER_URL}/${mainImgUrl})` }}
      />
      <div
        className={`project-info ${project.blank ? "" : "project-info-active"}`}
      >
        <h2 className="project-title">{title}</h2>
      </div>
    </div>
  );
};

export default ProjectCard;
