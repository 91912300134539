import axios from "axios";
import { API } from "../../utils/index";
const instance = axios.create();

export const postContact = async (data) => {
  try {
    return await instance.post(`${API}/contact`, data);
  } catch (error) {
    throw error;
  }
};
